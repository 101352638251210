import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { FaPaperPlane, FaCheck, FaEye, FaEyeSlash } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';

import api from '../../services/api';
import history from '../../services/history';

const schema = Yup.object().shape({
  usuario: Yup.string().required('O campo é obrigatório'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('O e-mail é obrigatório'),
});

const resetPassSchema = Yup.object().shape({
  password: Yup.string()
    .label('password'),
  newpass: Yup.string(),
});

export default function ForgotPassword() {
  const [query, setQuery] = useState('');
  const [message, setMessage] = useState('');
  const [mail, setMail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successToken, setSuccessToken] = useState(false);
  const [length, setLength] = useState('');
  const [upper, setUpper] = useState('');
  const [lower, setLower] = useState('');
  const [number, setNumber] = useState('');
  const [special, setSpecial] = useState('');
  const [type, setType] = useState('password');

  const recaptchaRef = React.useRef();

  /* useEffect(()=>{
    window.location.reload();
 }, []) */

  async function handleSubmit({ usuario, email }) {
    setMessage('');
    setMail(email);

    let captchaToken = null;
    const validacaoRecaptcha = await api.post('/auth/validacao-recaptcha');

    if (!validacaoRecaptcha.data) {
      captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
    }
    // Validação passa a ser feita somente no backend, pois temos ips que podem ser dispensados do recaptcha.
    // if (!captchaToken) {
    //   setMessage('Por favor, verifique o reCAPTCHA e tente novamente');
    //   return;
    // }

    try {
      setLoading(true);

      const csrf = await api.get('/sanctum/csrf-cookie');

      const response = await api.post('auth/recuperarsenha', {
        email,
        usuario,
        captchaToken: captchaToken,
      });

      const { error, mensagem } = response.data;

      if (error === 1) {
        setLoading(false);
        setMessage(mensagem);
      } else {
        setLoading(false);
        setSuccess(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage('Falha ao alterar sua senha, verifique seus dados!');
    }
  }

  async function handleResetPass({ password, newpass, token = query }) {
    try {
      if (password !== newpass) {
        setMessage('As senhas não são iguais');
        return;
      }
      if ((!length, !upper, !lower, !number, !special)) {
        setMessage('A senha não atende a todos os requisitos necessários!');
        return;
      }
      setMessage('');
      setLoading(true);

      const csrf = await api.get('/sanctum/csrf-cookie');

      const response = await api.post('auth/alterarsenha', {
        senha: password,
        token,
      });

      const { error, mensagem } = response.data;

      if (error === 1) {
        setLoading(false);
        setMessage(mensagem);
      } else {
        setLoading(false);
        setSuccessToken(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage('Falha ao alterar sua senha, verifique seus dados!');
    }
  }

  function handleChange(e) {
    setLength(!!e.target.value.match(/^.{8,}$/));
    setUpper(!!e.target.value.match(/[A-Z]/));
    setLower(!!e.target.value.match(/[a-z]/));
    setNumber(!!e.target.value.match(/[0-9]/));
    setSpecial(!!e.target.value.match(/\W|_/));
  }

  function handleClick() {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  }

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const foo = params.get('token_forgot');
    setQuery(foo);
  }, []);

  return (
    <>
      <div>
        {success ? (
          <>
            <span className="span-success">
              <FaPaperPlane size={21} />
              Quase lá!
            </span>
            <h4 className="message">
              {' '}
              Um e-mail foi enviado para {mail}. Lá você encontrará um link.
              Clique no link e será redirecionado para a redefinição da sua
              senha segura.
            </h4>
          </>
        ) : (
          <>
            {successToken ? (
              <>
                <span className="span-success">
                  <FaCheck size={21} />
                  Sucesso!
                </span>
                <h4 className="message">
                  Agora você pode fazer login na nossa plataforma e conferir
                  nossos produtos.
                </h4>

                <Link to="/">
                  <button type="button">Fazer login</button>
                </Link>
                <br />
              </>
            ) : (
              <>
                <span>
                  {/* <FaLock size={18} /> */}
                  Criar nova senha
                </span>
                {!query ? (
                  <Form
                    className="form"
                    schema={schema}
                    onSubmit={handleSubmit}
                  >
                    <label htmlFor="usuario">CNPJ ou usuário</label>
                    <Input
                      name="usuario"
                      autoComplete="off"
                      placeholder="Seu CNPJ ou usuário"
                      disabled={loading}
                    />
                    <label htmlFor="email">E-mail</label>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Seu e-mail"
                      autoComplete="off"
                      required
                      disabled={loading}
                    />
                    <span>{message}</span>

                    <div style={{ margin: '10px auto' }}>
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_V3}
                        badge="bottomright"
                        ref={recaptchaRef}
                        size="invisible"
                        hl={'pt-BR'}
                      />
                    </div>

                    <button type="submit">
                      {loading ? 'Carregando...' : 'Redefinir'}
                    </button>
                    <Link to="/">Já tenho cadastro</Link>
                  </Form>
                ) : (
                  <>
                    <h4 className="title">
                      Sua nova senha deve conter pelo menos
                    </h4>
                    <ul>
                      <li className={length ? 'crossed-text' : 'normal-text'}>
                        <FaCheck size={14} />8 caracteres.
                      </li>
                      <li className={upper ? 'crossed-text' : 'normal-text'}>
                        <FaCheck size={14} />
                        um caractere maiúsculo (A-Z)
                      </li>
                      <li className={lower ? 'crossed-text' : 'normal-text'}>
                        <FaCheck size={14} />
                        um caractere minúsculo (a-z)
                      </li>
                      <li className={number ? 'crossed-text' : 'normal-text'}>
                        <FaCheck size={14} />
                        um caractere númerico (0-9)
                      </li>
                      <li className={special ? 'crossed-text' : 'normal-text'}>
                        <FaCheck size={14} />
                        um caractere especial (*, @, !, $, %, ...)
                      </li>
                    </ul>
                    <Form
                      className="form"
                      schema={resetPassSchema}
                      onSubmit={handleResetPass}
                    >
                      <div className="icons">
                        <label htmlFor="password">Nova senha</label>
                        <Input
                          className="input-icon"
                          name="password"
                          type={type}
                          autoComplete="new-password"
                          onChange={handleChange}
                          disabled={loading}
                        />
                        <button
                          type="button"
                          className="button-icon"
                          onClick={handleClick}
                        >
                          {type === 'text' ? (
                            <FaEyeSlash size={15} />
                          ) : (
                            <FaEye size={15} />
                          )}
                        </button>
                        <label htmlFor="newpass">Confirmar nova senha</label>
                        <Input
                          className="input-icon"
                          name="newpass"
                          type={type}
                          autoComplete="new-password"
                          disabled={loading}
                        />
                        <button
                          type="button"
                          className="button-icon"
                          onClick={handleClick}
                        >
                          {type === 'text' ? (
                            <FaEyeSlash size={15} />
                          ) : (
                            <FaEye size={15} />
                          )}
                        </button>
                      </div>
                      <span>{message}</span>

                      <button type="submit">
                        {loading ? 'Carregando...' : 'Enviar'}
                      </button>
                      <Link to="/">Já tenho cadastro</Link>
                    </Form>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
