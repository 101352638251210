import styled from 'styled-components';
import { darken } from 'polished';

export const Root = `
`;

export const Wrapper = styled.div`
  display: flex;
  // height: 60%;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  text-align: center;
  margin-top: 40px;

  .span-success {
    @media only screen and (max-width: 400px) {
      padding: 0;
      div {
        width: 280px !important;
      }
    }
    padding: 20px;
    color: green;
    margin-top: -50px;
  }

  .span-success-title {
    font-weight: 600;
  }

  .span-success-content {
    font-size: 17px;
    max-width: 500px;
    display: block;
    margin: auto;
    padding-top: 20px;
  }

  .message {
    padding: 20px 20px;
    font-weight: 500;
    font-size: 18px;
  }

  div {
    h2 {
      padding-top: 30px;
      color: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
    }
    background: #fff;
    span {
      text-align: center;
      font-size: 25px;
      color: rgba(25, 25, 32, 0.7)
      svg {
        margin: 0 3px;
      }
    }
    button[type='submit'],
    .button-catalog {
      margin: 5px 0 0;
      height: 44px;
      background: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
      font-weight: bold;
      color: #fff;
      border: 0;
      min-width: 45%;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      padding: 15px;
      &:hover {
        background: ${darken(0.03, process.env.REACT_APP_COLOR_BRAND_PRIMARY)};
      }
    }

    .form {
      border-radius: 3px;
      padding-top: 25px;
      padding: 0 25px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      flex-wrap: wrap;
      max-width: 460px;
      margin: 0 auto;
      justify-content: center;

      @media only screen and (max-width: 768px) {
        padding: 0 10px;
      }

      .button-icon {
        background: rgba(0, 0, 0, 0.1);
        border-radius: 0 4px 4px 0;
        margin: 0;
        padding: 0;
        color: #000;
        position: relative;
        min-width: 10%;
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      .button-next {
        background: white;
        border: none;
        font-size: 12px;
      }

      .form-item {
        flex: 1 0 auto;
        width: calc(50% - 5px);
        display: flex;
        flex-direction: column;
        margin-right: 5px;

        @media only screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .form-item-horizontal {
        display: flex;
        width: calc(50% - 5px);
        flex: 0 1 50%;
      }

      div {
        flex: 1 0 100%;
      }

      @media only screen and (max-width: 768px) {
        left: 5%;
      }
      @media only screen and (max-width: 425px) {
        left: 0%;
      }
      label {
        float: left;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        padding: 5px 0;
        color: rgba(25, 25, 32, 0.7);
      }
      input,
      select {
        background: hsla(0, 0%, 100%, 0.8);
        border-radius: 5px;
        height: 44px;
        padding: 0 10px;
        color: #333;
        margin: 0 0 20px;
        border: 1px solid #d6d6d6;
        font-size: 15px;
        font-family: arial;
        transition: 0.2s

        &::placeholder {
          color: rgba(25, 25, 32, 0.52);
        }

        &:focus,
        :active {
          border-color: #5d5c62;
        }

        &[readonly] {
          background: #fff9cb;
          font-weight: bold;
        }
      }
      span {
        color: red;
        align-self: flex-start;
        margin: 0 0 10px;
        font-weight: bold;
        font-size: 15px;
      }

      small {
        // text-align: right;
        // margin-bottom: 5px !important;
        text-align: left;
        margin-top: -10px;
        color: #5d5c62;
        a {
          color: #191920;
          font-size: 12px;
          opacity: 0.8;
          font-weight: bold;
        }
      }
      h4 {
        color: rgba(25, 25, 32, 0.8);
        font-size: 15px;
        opacity: 0.8;
      }
      a {
        color: #191920;
        margin-top: 15px;
        font-size: 15px;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;
