import React from 'react';
import PropTypes from 'prop-types';

import { Container, Content, Root } from './styles';
import logo from '../../../assets/logoDPC.svg';
import fundoPrincipal from '../../../assets/fundo-principal.png';
import Header from '../../../components/Header';

export default function AuthLayout({ children }) {
  const fundoPrincipal = process.env.REACT_APP_BACKGROUND_URL;
  return (
    <>
      <Container>
        <Header />
      </Container>
      <Content>
        {children}
        {fundoPrincipal && (
          <div className="fundo-principal">
            <div
              style={{
                backgroundImage: `url(${fundoPrincipal})`,
                height: '100%',
                width: '100%',
                opacity: '0.2',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'brightness(0.6)',
              }}
            />
            <img
              className="fundo-logo"
              src={process.env.REACT_APP_LOGO_URL || logo}
              alt={process.env.REACT_APP_NAME}
            />
          </div>
        )}
      </Content>
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
