import styled from 'styled-components';
import { darken } from 'polished';

export const Root = `
`;

export const Container = styled.div`
  @media only screen and (min-width: 768px) {
    display: none;
  }

  background: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};

  img {
    filter: none !important;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  place-content: center;

  @media only screen and (max-width: 768px) {
    height: calc(100vh - 125px);
  }

  > * {
    flex-basis: 50%;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
      flex-basis: 100%;
    }
  }

  .fundo-principal {
    @media only screen and (max-width: 768px) {
      display: none;
    }
    width: 50%;
    height: 100%;
    position: relative;
    z-index: -1;

    background-color: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};

    .fundo-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 70px !important;
    }
  }

  .input-icon {
    width: 90%;
    border-radius: 4px 0 0 4px !important;
  }

  .span-success {
    padding: 20px;
    color: green;
  }
  .message {
    padding: 20px 20px;
    font-weight: 500;
    font-size: 18px;
  }
  .crossed-text {
    color: #1c8c48;
    text-decoration: line-through;
    text-align: justify;
    position: relative;
    left: 26%;
    opacity: 0.8;
    max-width: 400px;
    i {
      color: #1c8c48;
    }
    svg {
      margin-right: 10px;
    }
  }
  .title {
    font-size: 18px;
    color: rgba(25, 25, 32, 1);
    font-weight: bold;
    text-align: center;
    position: relative;
    margin: 15px 0px;
    @media only screen and (max-width: 768px) {
      left: 11%;
    }
    @media only screen and (max-width: 425px) {
      left: 0%;
    }
  }
  .normal-text {
    color: rgba(25, 25, 32, 1);
    font-size: 15px;
    text-align: justify;
    position: relative;
    left: 26%;
    opacity: 0.8;
    max-width: 400px;
    @media only screen and (max-width: 768px) {
      left: 5%;
    }
    @media only screen and (max-width: 425px) {
      left: 0%;
    }
    svg {
      margin-right: 10px;
    }
  }
  div {
    background: #fff;
    span {
      text-align: center;
      display: inline-block;
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 15px;
      max-width: 460px;
      padding: 0 25px;
      text-align: left;
      color: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
      svg {
        margin: 0 3px;
      }
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
      font-weight: bold;
      color: #fff;
      border: 0;
      min-width: 30%;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, process.env.REACT_APP_COLOR_BRAND_PRIMARY)};
      }
    }
    .form {
      border-radius: 3px;
      padding-top: 25px;
      padding: 0 25px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      flex-wrap: wrap;
      max-width: 460px;
      margin: 0 auto;

      @media only screen and (max-width: 768px) {
        padding: 0 10px;
      }

      .button-icon {
        background: white;
        border-radius: 0 4px 4px 0;
        margin: 0;
        padding: 0;
        color: #000;
        position: relative;
        min-width: 10%;
        margin-top: 3px;
        border: 1px solid #d6d6d6;
        border-left: none;
        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }
      }

      div {
        flex: 1 0 100%;
        text-align: left;
      }

      @media only screen and (max-width: 768px) {
        left: 5%;
      }
      @media only screen and (max-width: 425px) {
        left: 0%;
      }
      label {
        float: left;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        padding: 5px 0;
        color: rgba(25, 25, 32, 0.7);
      }
      label.terms {
        float: none;
        font-weight: 500;
        font-size: 16px;
      }

      label.label-conectado {
        float: left;
        text-align: none;
        font-weight: 500;
        font-size: 13px;
        margin-left: 10px;
      }

      .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        background: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
        transition: 0s;

        svg {
          background-color: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};

          path {
            fill: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
          }
        }
      }

      .react-toggle--checked .react-toggle-track {
        background: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
        svg {
          background-color: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
          path {
            fill: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
          }
        }
      }

      .react-toggle-track {
        background-color: #4d4d4d;
        transition: 0s;
        svg {
          background-color: #4d4d4d;

          path {
            fill: #4d4d4d;
          }
        }
      }

      .react-toggle {
        float: left;
      }

      .react-toggle--checked {
        .react-toggle-thumb {
          border-color: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
        }
      }

      input {
        background: hsla(0, 0%, 100%, 0.8);
        border-radius: 5px;
        height: 44px;
        padding: 0 10px;
        color: #333;
        margin: 0 0 20px;
        border: 1px solid #d6d6d6;
        font-size: 15px;
        font-family: arial;
        transition: 0.2s

        &::placeholder {
          color: rgba(25, 25, 32, 0.52);
        }

        &:focus,
        :active {
          border-color: #5d5c62;
        }

        &[readonly] {
          background: #fff9cb;
          font-weight: bold;
        }
      }

      input[type='checkbox'] {
        margin-right: 10px;
        margin-top: 10px;
        height: 15px;
        margin-bottom: 10px;
        width: 15px;
      }

      span {
        color: red;
        align-self: flex-start;
        margin: 0 0 10px;
        font-weight: bold;
        font-size: 15px;
      }
      button {
        margin: 5px 0 0;
        height: 44px;
        background: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
        font-weight: bold;
        color: #fff;
        border: 0;
        border-radius: 4px;
        font-size: 16px;
        transition: background 0.2s;
        &:hover {
          background: ${darken(
            0.03,
            process.env.REACT_APP_COLOR_BRAND_PRIMARY
          )};
        }

        @media only screen and (max-width: 736px) {
          padding: 10px;
        }
      }
      small {
        float: right;
        // margin-bottom: 5px !important;
        color: #5d5c62;
        margin-top: 5px;
        a {
          color: #191920;
          font-size: 13px;
          opacity: 0.8;
          font-weight: bold;
        }
      }
      h4 {
        color: rgba(25, 25, 32, 0.8);
        font-size: 15px;
        opacity: 0.8;
      }
      a {
        color: #191920;
        margin-top: 15px;
        font-size: 15px;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }

        b {
          color: ${process.env.REACT_APP_COLOR_BRAND_PRIMARY};
        }
      }
    }
  }
`;
