/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import MailCheck from 'react-mailcheck';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import ReCAPTCHA from 'react-google-recaptcha';
import AuthLayout from '../../../pages/_layouts/auth';

const schema = Yup.object().shape({
  cnpj: Yup.string()
    .matches(
      /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/g,
      'CNPJ inválido!'
    )
    .required('Por favor, insira um CNPJ.'),

  email: Yup.string()
    .email()
    .required('Por favor, insira um e-mail.'),
});

const Step1 = props => {
  const { userData, onSetUserData, currentStep } = props;
  const [email, setEmail] = useState(props.userData.email || '');
  const [cnpj, setCnpj] = useState(maskCnpj(props.userData.cnpj));
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [errCode, setError] = useState(null);
  const [termsChecked, setTermsChecked] = useState(false);

  const recaptchaRef = React.useRef();

  const handleSubmit = async formData => {
    setMessage('');
    setError(null);

    let captchaToken = null;
    const validacaoRecaptcha = await api.post('/auth/validacao-recaptcha');

    if (!validacaoRecaptcha.data) {
      captchaToken = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
    }
    const { cnpj, email } = formData;

    try {
      setLoading(true);

      const csrf = await api.get('/sanctum/csrf-cookie');

      const response = await api.post('auth/precadastro', {
        documento: cnpj,
        captchaToken: captchaToken,
        email,
      });

      const { error, mensagem, dados, errCode } = response.data;

      if (error !== 1) {
        setLoading(false);
        setMessage({ type: 1, msg: mensagem });

        if (dados) {
          const userDataReceived = {
            email,
            nome: dados.nomerazao ? dados.nomerazao : undefined,
            fantasia: dados.fantasia ? dados.fantasia : undefined,
            rgie: dados.rgie ? dados.rgie : undefined,
            cnpj: dados.cnpj ? dados.cnpj : undefined,
            cep: dados.cep ? dados.cep : undefined,
            endereco: dados.logradouro ? dados.logradouro : undefined,
            numero: dados.numero ? dados.numero : undefined,
            complemento: dados.complemento ? dados.complemento : undefined,
            bairro: dados.bairro ? dados.bairro : undefined,
            cidade: dados.cidade ? dados.cidade : undefined,
            estado: dados.uf ? dados.uf : undefined,
            dadosinseridos: dados.dadosinseridos
              ? dados.dadosinseridos
              : undefined,
            nomesocio1: dados.nomesocio1 ? dados.nomesocio1 : undefined,
            cnpjcpfsocio1: dados.cnpjcpfsocio1
              ? dados.cnpjcpfsocio1
              : undefined,
            nomesocio2: dados.nomesocio2 ? dados.nomesocio2 : undefined,
            cnpjcpfsocio2: dados.cnpjcpfsocio2
              ? dados.cnpjcpfsocio2
              : undefined,
            nomesocio3: dados.nomesocio3 ? dados.nomesocio3 : undefined,
            cnpjcpfsocio3: dados.cnpjcpfsocio3
              ? dados.cnpjcpfsocio3
              : undefined,
          };

          onSetUserData(userDataReceived);

          setMessage('');

          // Jump to the next step if everything is okay
          const { jumpToStep } = props;
          jumpToStep(currentStep + 1);
        }
      } else {
        setError(errCode);

        // console.log(errCode);

        // eslint-disable-next-line no-throw-literal
        throw { err: mensagem };
      }
    } catch (err) {
      setMessage({
        type: 2,
        msg:
          err.err ||
          'Ops! Ocorreu um erro ao analisar seus dados. Por favor, tente novamente.',
      });
    }

    setLoading(false);
  };

  function handleClickMailcheck(suggestion) {
    setEmail(suggestion.full);
  }

  function handleChange(event) {
    setEmail(event.target.value);
  }

  function maskCnpj(cnpj){
    if(!cnpj){
      return '';
    }
    return cnpj
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura os dois últimos 2 números, com um - antes dos dois números

  }

  function handleChangeCnpj(event) {
    setCnpj(maskCnpj(event.target.value));
  }

  return (
    <AuthLayout>
      <div className="step step3">
        <div className="row">
          <span>Faça o seu cadastro e aproveite!</span>
          <br />
          <br />
          <Form
            schema={schema}
            onSubmit={handleSubmit}
            className="form"
            initialData={{ cnpj: userData.cnpj, email: userData.email }}
          >
            <label htmlFor="cnpj">CNPJ*</label>
            <Input
              id="cnpj"
              placeholder="Seu CNPJ"
              name="cnpj"
              maxLength="18"
              className="form-control"
              disabled={loading}
              inputMode="decimal"
              onChange={handleChangeCnpj}
              value={cnpj}
            />

            <label htmlFor="email">Email*</label>
            <MailCheck email={email}>
              {suggestion => (
                <>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email do cliente"
                    className="form-control"
                    onChange={handleChange}
                    value={email}
                    required
                    autoComplete="email"
                    disabled={loading}
                    inputMode="email"
                  />

                  {suggestion && (
                    <div className="mailcheck">
                      Você quis dizer{' '}
                      <button
                        type="button"
                        className="mailcheck-btn"
                        onClick={() => handleClickMailcheck(suggestion)}
                      >
                        {suggestion.full}
                      </button>
                      ?
                    </div>
                  )}
                </>
              )}
            </MailCheck>

            <div>
              <input
                type="checkbox"
                name="terms"
                id="terms"
                checked={termsChecked}
                onChange={e => setTermsChecked(e.target.checked)}
                required
              />
              <label className="terms" htmlFor="terms">
                Ao criar a minha conta estou de acordo com a
                <a target="_blank" href={process.env.REACT_APP_TERMS_URL}>
                  <b> política de privacidade</b>
                </a>
              </label>
            </div>

            <div>
              <span
                style={{
                  color:
                    message.type === 1
                      ? '#00C853'
                      : message.type === 2
                      ? '#dc8500'
                      : '',
                  paddingTop: '10px',
                }}
              >
                {message.msg}
              </span>
            </div>

            <div style={{ margin: '10px auto' }}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY_V3}
                badge="bottomright"
                ref={recaptchaRef}
                size="invisible"
                hl={'pt-BR'}
              />
            </div>
            <button type="submit" disabled={loading}>
              {loading ? 'Carregando...' : 'Criar meu cadastro'}
            </button>
            <Link to="/">
              Já tem uma conta? <b>Faça login</b>
            </Link>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
};

Step1.propTypes = {
  userData: PropTypes.object.isRequired,
  onSetUserData: PropTypes.func.isRequired,
  jumpToStep: PropTypes.func,
  currentStep: PropTypes.number.isRequired,
};

Step1.defaultProps = {
  jumpToStep: () => {},
  // userData: () => {},
};

export default Step1;
