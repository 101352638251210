import React from 'react';

import logo from '../../assets/logoDPCDark.svg';
import { Container, Content } from './styles';

export default function Header() {
  return (
    <Container>
      <Content>
        <a target="_self" href={`${process.env.REACT_APP_REDIRECT_URL}`}>
          <img
            src={process.env.REACT_APP_LOGO_URL || logo}
            alt={process.env.REACT_APP_NAME}
          />
        </a>
      </Content>
    </Container>
  );
}
